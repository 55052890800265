<template>
  <div class="monitoring-container">
    <!-- title -->
    <div class="title-inner title-inner--md">
      {{ containerTitle }}
    </div>

    <!-- filter analysis -->
    <div class="monitoring-content__header">
      <template v-if="viewAllFoiv && totalDataByFoiv">
        <InfoCount title="Всего поручений" :count="totalDataByFoiv.totalCount" justifyClass="flex-start" />
      </template>
      <div class="monitoring-content__filter filter">
        <div class="filter__item assignmentMonitoring__inputBlock">
          <p class="label-field">{{ firstLabel }}</p>
          <SelectSingleComponentCheckbox
            v-model="firstFilter"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :placeholder="firstPlaceholder"
            :options="firstSelectList"
            :canClear="false"
            mod="small"
          />
        </div>

        <div class="filter__item assignmentMonitoring__inputBlock">
          <p class="label-field">
            {{ secondLabel }}
          </p>
          <SelectSingleComponentCheckbox
            v-model="secondFilter"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :placeholder="secondPlaceholder"
            :options="secondSelectList"
            :canClear="false"
            mod="small"
          />
        </div>
      </div>

      <slot></slot>
    </div>
    <!-- charts -->
    <div class="monitoring-content__chart">
      <ChartBarMonitoringRedesigned :seriesProp="dataMonitoring" :nn="nn" />
    </div>
    <!-- btns & indicators -->
    <div class="monitoring-content__footer">
      <div class="monitoring-content__show-more">
        <ButtonComponent
          v-if="dataMonitoring.length < dataMonitoringAll.length"
          class="monitoring-content__show-more-item button-redesign--show-more"
          @click="setLimits(SET_LIMIT_MOD.ADD)"
        >
          Показать еще
          <IconComponent name="plus-mini" class="icon--right" />
        </ButtonComponent>

        <ButtonComponent
          v-if="dataMonitoring.length < dataMonitoringAll.length"
          class="monitoring-content__show-more-item button-redesign--show-more"
          @click="setLimits(SET_LIMIT_MOD.ALL)"
        >
          Показать все
          <IconComponent name="plus-mini" class="icon--right" />
        </ButtonComponent>

        <ButtonComponent
          v-if="dataMonitoring.length > dataMonitoringInitLength"
          class="monitoring-content__show-more-item monitoring-content__show-more-item--reset button-redesign--show-more"
          @click="setLimits()"
          style="color: #dc3545"
        >
          Скрыть
          <IconComponent name="minus-mini" class="icon--right" />
        </ButtonComponent>
      </div>
      <ButtonComponent @click="goto" class="button-redesign--border button-redesign--mobile-full"
        >Подробная информация</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import InfoCount from '@/components/InfoCount';

  import { SET_LIMIT_MOD } from '../../utils/constants';
  import ChartBarMonitoringRedesigned from './ChartBarMonitoringRedesigned';

  export default {
    components: {
      InfoCount,
      ButtonComponent,
      ChartBarMonitoringRedesigned,
      SelectSingleComponentCheckbox,
      IconComponent,
    },
    emits: ['update:firstFilterModel', 'update:secondFilterModel', 'setLimits'],
    props: {
      containerTitle: {
        type: String,
        default: '',
      },
      totalDataByFoiv: {
        type: Object,
      },
      viewAllFoiv: {
        type: Boolean,
        default: false,
      },
      firstLabel: {
        type: String,
        default: '',
      },
      firstFilterModel: {
        type: [String, null],
        required: true,
      },
      firstSelectList: {
        type: Array,
        required: true,
      },
      firstPlaceholder: {
        type: String,
        default: '',
      },
      secondLabel: {
        type: String,
        default: '',
      },
      secondFilterModel: {
        type: [String, null],
        required: true,
      },
      secondSelectList: {
        type: Array,
        required: true,
      },
      secondPlaceholder: {
        type: String,
        default: '',
      },
      dataMonitoring: {
        type: Array,
        required: true,
      },
      dataMonitoringAll: {
        type: Array,
        required: true,
      },
      dataMonitoringInitLength: {
        type: Number,
        required: true,
      },
      nn: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        SET_LIMIT_MOD,
      };
    },
    computed: {
      firstFilter: {
        get() {
          return this.firstFilterModel;
        },
        set(value) {
          this.$emit('update:firstFilterModel', value);
        },
      },
      secondFilter: {
        get() {
          return this.secondFilterModel;
        },
        set(value) {
          this.$emit('update:secondFilterModel', value);
        },
      },
    },
    methods: {
      setLimits(value) {
        this.$emit('setLimits', value);
      },
      goto() {
        this.$router.push({ name: 'Assignments', query: this.$route?.query });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/AssignmentMonitoring.scss';

  .monitoring {
    &-content {
      &__header {
        & .filter {
          display: flex;
          flex-direction: row;

          @media (max-width: $laptop) {
            flex-direction: column;
          }

          &__item {
            margin-right: 16px;
            max-width: 416px;
            width: 100%;
            font-weight: 900;

            @media (max-width: $laptop) {
              margin-right: 0;
              margin-bottom: 20px;
              max-width: none;
            }

            &:last-child {
              margin-right: 0;

              @media (max-width: $laptop) {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &__filter {
        margin: 40px 0 24px;

        @media (max-width: $laptop) {
          margin: 20px 0 20px;
        }
      }

      &__show-more {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: auto;

        @media (max-width: $laptop) {
          align-content: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 16px;
        }

        &-item {
          margin-right: 16px;

          @media (max-width: $laptop) {
            width: 100%;
            max-width: 116px;
          }

          &:last-child {
            margin-right: 0;
          }

          &--reset {
            @media (max-width: $laptop) {
              width: 100%;
              max-width: none;
              margin-top: 8px;
            }
          }
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $laptop) {
          flex-direction: column;
        }
      }
    }
  }

  .monitoring-container {
    &:not(:last-of-type) {
      margin-bottom: 70px;
    }
  }

  @media (max-width: $laptop) {
    .monitoring-content__footer button {
      position: initial;
    }
  }
</style>
