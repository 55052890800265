import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/mpkAssignment/search', { params: request });
  },
  getEventForList: function (request) {
    return axios.get('/api/mpkAssignment/getEventForList', { params: request });
  },
  getMpkRegistryAssignmentStatusList: function () {
    return axios.get('api/MpkAssignment/getMpkRegistryAssignmentStatusList');
  },
  getTotal: function (request) {
    return axios.get('/api/mpkAssignment/getTotal', { params: request });
  },
  getTotalByFoiv: function (request) {
    return axios.get('/api/mpkAssignment/getTotalByFoiv', { params: request });
  },
  getTotalByCountry: function (request) {
    return axios.get('/api/mpkAssignment/getTotalByCountry', {
      params: request,
    });
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/mpkAssignment/exportExcel', 'MpkAssignment.xlsx', request);
  },
  exportFile: function (request) {
    return Utils.downloadFile('/api/mpkAssignment/exportFile', '', request);
  },
  find: function (id) {
    return axios.get(`/api/mpkAssignment/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/mpkAssignment/update?id=${data.id}`, data);
    else return axios.post(`/api/mpkAssignment/create`, data);
  },
  delete: function (id) {
    return axios.delete('/api/mpkAssignment/delete?id=' + id);
  },
  deleteExecutor: function (id) {
    return axios.delete('/api/mpkAssignment/deleteExecutor?id=' + id);
  },
  cancelStatus: function (data) {
    return axios.put(`/api/mpkAssignment/cancelStatus?id=${data.assignmentExecutorId}`, data);
  },
  refuseReport: function (data) {
    return axios.put(`/api/mpkAssignment/refuseReport?id=${data.assignmentExecutorId}`, data);
  },
  approveReport: function (data) {
    return axios.put(`/api/mpkAssignment/approveReport?id=${data.id}`, data);
  },
  updateReport: function (data) {
    return axios.put(`/api/mpkAssignment/updateReport?id=${data.id}`, data);
  },
  setPostponed: function (id, state) {
    return axios.put(`/api/mpkAssignment/setPostponed?id=${id}&state=${state}`);
  },
  createChat: function (data) {
    return axios.post(
      `/api/mpkAssignment/CreateChatForAssignment?assignmentId=${data.assignmentId}&orgId=${data.orgId}`,
    );
  },
  getChats: function (id) {
    return axios.get(`/api/mpkAssignment/getChats?id=${id}`);
  },
  exportFileMonitoring: function (request) {
    return Utils.downloadFile('/api/mpkAssignment/exportFileMonitoring', '', request);
  },
  sendComment: function (data) {
    return axios.post(`/api/mpkAssignment/sendComment`, data);
  },
  startAssignment: function (data) {
    return axios.put(`/api/mpkAssignment/start?id=${data.id}`, data);
  },
  startMultiple: function (data) {
    return axios.put(`/api/mpkAssignment/startMultiple`, data);
  },
  changeStatus: function (data) {
    return axios.put(`/api/mpkAssignment/changeStatusMultiple`, data);
  },
  changeDate: function (data) {
    return axios.put(`/api/mpkAssignment/changeDateMultiple`, data);
  },
  getUploadTemplate: function () {
    return Utils.downloadFile('/api/mpkAssignment/getUploadTemplate', 'MpkAssignmentUploadTemplate.xlsx');
  },
  exportPptx: function (filters) {
    return Utils.downloadFile('/api/mpkAssignment/exportPptx', '', filters);
  },
  uploadFile(file, status) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`api/mpkAssignment/uploaddata/${status}`, formData);
  },
  returnFromDone: function (id) {
    return axios.put(`/api/mpkAssignment/returnFromDone/${id}`);
  },
};
