<template>
  <div class="apexchart-monitoring">
    <div class="apexchart-content__wrapper">
      <!-- ref может использоваться в родителе -->
      <apexchart
        ref="apexchart"
        class="apexchart-content apexchart-content__chart"
        type="bar"
        :options="chartOptions"
        :series="series"
      >
      </apexchart>
      <ChartMobile class="apexchart-content__chart-mobile" :options="chartOptions" :series="series" />
    </div>
    <div v-if="template === 'default'" class="apexchart-legend">
      <ChartIndicators :indicatorsList="legendDataVisible" />
    </div>
  </div>
</template>

<script>
  import ChartIndicators from '@/components/ChartComponents/ChartIndicators';
  import ChartMobile from '@/components/ChartComponents/ChartMobile';

  export default {
    name: 'ChartBarMonitoringRedesigned',
    components: {
      ChartIndicators,
      ChartMobile,
    },
    props: {
      seriesProp: {
        type: Array,
      },
      template: {
        default: 'default',
        type: String,
      },
      nn: Number,
    },
    watch: {
      nn: function () {
        this.loadChart();
        this.isLoaded = true;
      },
      seriesProp: function () {
        this.loadChart();
        this.isLoaded = true;
      },
    },
    data() {
      return {
        isLoaded: false,
        legendData: [
          { label: 'Выполнено', color: '1, 163, 157, 1' },
          { label: 'Не выполнено', color: '255, 102, 102, 1' },
          { label: 'В стадии выполнения', color: '23, 161, 250, 1' },
        ],
        // hiddenLegendItems: [],
        focusedLegendItems: [],
        // data for templates
        xAxisCategories: [],
        oneArray: [],
        twoArray: [],
        threeArray: [],
        // stock options
        series: [
          {
            name: 'series-1',
            data: [30, 40, 45, 50, 49, 60, 70, 81],
          },
        ],
        chartOptions: {
          grid: {
            show: false,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                align: 'left',
                maxWidth: 200,
                minWidth: 200,
                offsetX: 0,
                offsetY: 4,
              },
              lines: {
                show: false,
              },
            },
          },
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#78D28C', '#003990', '#F04F4F', '#64ADEF', '#008C6E', '#007EC5'],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '60%',
              barWidth: '100%',
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          yaxis: {
            labels: {
              align: 'left',
              offsetX: -20,
              offsetY: 4,
              maxWidth: 200,
              minWidth: 200,
              style: {
                fontSize: '16px',
                fontWeight: 500,
                fontFamily: 'Fira Sans',
                colors: '#333',
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '16px',
                fontFamily: 'Fira Sans',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              },
            },
          },
          noData: {
            text: 'Загрузка...',
          },
        },
      };
    },
    methods: {
      xAxisCategoriesHandlePush(item) {
        if (item.length < 25) {
          this.xAxisCategories.push(item);
          return;
        }
        const itemArray = item.replace('\n', '').trim().split(` `);
        const itemLength = itemArray.length;
        if (itemLength > 3) {
          const separatorIndex = Math.round(itemLength / 2);
          const firstLine = itemArray.slice(0, separatorIndex).join(` `);
          const secondLine = itemArray.slice(separatorIndex, itemLength).join(` `);
          this.xAxisCategories.push([firstLine, secondLine]);
          return;
        }
        const isFirstWordLonder = itemArray[0].length > itemArray[itemLength - 1].length;
        const firstLine = isFirstWordLonder ? itemArray[0] : itemArray.slice(0, itemLength - 1).join(` `);
        const secondLine = isFirstWordLonder ? itemArray.slice(1, itemLength).join(` `) : itemArray[itemLength - 1];
        this.xAxisCategories.push([firstLine, secondLine]);
      },
      // clickLegendItem(name) {
      //   if (!this.$refs.apexchart) return;
      //   this.$refs.apexchart.toggleSeries(name);
      //   if (this.hiddenLegendItems.includes(name)) {
      //     this.hiddenLegendItems = this.hiddenLegendItems.filter((item) => item !== name);
      //     return;
      //   }
      //   this.hiddenLegendItems.push(name);
      // },
      // mouseoverLegendItem(name) {
      //   if (!this.$refs.apexchart || this.hiddenLegendItems.includes(name)) return;
      //   this.focusedLegendItems.push(name);
      //   this.$refs.apexchart.updateOptions(this.barOfComplatingOptions);
      // },
      // mouseleaveLegendItem() {
      //   if (!this.$refs.apexchart) return;
      //   this.focusedLegendItems = [];
      //   this.$refs.apexchart.updateOptions(this.barOfComplatingOptions);
      // },
      // templates
      // ex. monitoring-work-mpk.vue
      doneChart() {
        this.xAxisCategories = [];
        this.oneArray = [];
        this.twoArray = [];
        this.threeArray = [];

        // Массив для категорий
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].name;
          this.xAxisCategoriesHandlePush(item);
        }

        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[0];
          this.oneArray.push(item);
        }
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[1];
          this.twoArray.push(item);
        }
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[2];
          this.threeArray.push(item);
        }
        this.chartOptions = this.barOfComplatingOptions;
        this.series = [
          {
            name: this.legendData[0].label,
            data: this.oneArray,
          },
          {
            name: this.legendData[1].label,
            data: this.twoArray,
          },
          {
            name: this.legendData[2].label,
            data: this.threeArray,
          },
        ];
      },
      // ex. analytics-projects.vue
      analyticsChart() {
        // Массив для категорий
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].name;
          this.xAxisCategories.push(item);
        }
        //
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[0];
          this.oneArray.push(item);
        }
        this.chartOptions = {
          chart: {
            type: 'bar',
            height: 55,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
              barHeight: '90%',
              barWidth: '100%',
              dataLabels: {
                position: 'bottom',
              },
            },
          },
          colors: ['#01A39D', '#01CCC5', '#26CA98', '#018BBF', '#1D87EE', '#2b908f', '#69d2e7'],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['black'],
              fontSize: '16px',
              fontFamily: 'Fira Sans',
              fontWeight: '400',
            },
            formatter: function (val, opt) {
              return val + '  ' + opt.w.globals.labels[opt.dataPointIndex];
            },
            offsetX: 0,
          },
          xaxis: {
            categories: this.xAxisCategories,
            toolbar: {
              show: false,
            },
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
          },
          yaxis: {
            toolbar: {
              show: false,
            },
            labels: {
              show: this.seriesProp.length > 0,
              align: 'left',
              offsetX: -20,
              offsetY: 4,
              maxWidth: 200,
              minWidth: 200,
              style: {
                fontSize: '16px',
                fontFamily: 'Fira Sans',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          grid: {
            show: false,
          },
        };
        this.series = [
          {
            data: this.oneArray,
          },
        ];
      },
      loadChart() {
        // выбор шаблона
        // ex. monitoring-work-mpk.vue
        if (this.template == 'default') {
          this.doneChart();
        }
        // ex. analytics-projects.vue
        else if (this.template == 'analytics') {
          this.analyticsChart();
        }
      },
    },
    created() {
      this.loadChart();
    },
    computed: {
      legendDataVisible() {
        return [
          ...[this.oneArray.some((item) => item !== 0) ? this.legendData[0] : null],
          ...[this.twoArray.some((item) => item !== 0) ? this.legendData[1] : null],
          ...[this.threeArray.some((item) => item !== 0) ? this.legendData[2] : null],
        ].filter((el) => !!el);
      },
      barOfComplatingColors() {
        return this.legendData.map((item) => {
          if (!this.focusedLegendItems.length) return `rgba(${item.color})`;
          if (this.focusedLegendItems.includes(item.label)) return `rgba(${item.color})`;
          return `rgba(${item.color.replace('1', '0.2')})`;
        });
      },
      barOfComplatingOptions() {
        return {
          dataLabels: {
            style: {
              colors: ['#ffffff'],
            },
          },
          chart: {
            type: 'bar',
            height: Math.max(100, this.seriesProp.length * 30 + 25),
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '20px',
              barWidth: '100%',
            },
          },
          colors: this.barOfComplatingColors,
          xaxis: {
            categories: this.xAxisCategories,
            labels: {
              show: false,
              style: {
                fontSize: '16px',
                fontFamily: 'Fira Sans',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              },
            },
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: this.seriesProp.length > 0,
              align: 'left',
              offsetX: -20,
              offsetY: 4,
              maxWidth: 200,
              minWidth: 200,
              style: {
                fontSize: '16px',
                fontFamily: 'Fira Sans',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              },
              formatter: function (val) {
                if (typeof val === 'object') {
                  return `${val[0]} ${val[1]}`;
                }

                return val;
              },
            },
          },
          grid: {
            show: false,
          },
          legend: {
            show: false,
          },
          tooltip: {
            followCursor: true,
          },
          noData: {
            text: this.isLoaded ? 'Ничего не найдено' : 'Загрузка...',
          },
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .apexchart-monitoring {
    position: relative;
  }

  .apexchart-content {
    min-width: 500px;

    &__chart {
      display: block;

      @media (max-width: $laptop) {
        display: none;
      }
    }

    &__chart-mobile {
      display: none;

      @media (max-width: $laptop) {
        display: block;
        margin-bottom: 16px;
      }
    }

    &__wrapper {
      overflow: auto;
    }
  }

  .apexchart-legend {
    inset: auto 0 0 0;
    position: absolute;
    display: flex;

    @media (max-width: 900px) {
      position: static;
      flex-wrap: wrap;
      inset: unset;
    }

    @media (max-width: $laptop) {
      margin-bottom: 16px;
    }

    &__item {
      cursor: pointer;
      display: flex;
      margin: 20px 32px;
      align-items: center;

      &-color {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        margin-bottom: 2px;
        margin-right: 3px;
      }

      &-label {
        color: rgb(55, 61, 63);
        font-size: 14px;
        font-weight: 400;
        font-family: Helvetica, Arial, sans-serif;
      }

      &.pale {
        opacity: 0.5;
      }
    }
  }
</style>
