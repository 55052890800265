<template>
  <div class="info-count-list">
    <div class="info-count-list__item" v-for="(items, index) in infoCountList" :key="items.title">
      <InfoCount :title="items.title" :count="items.count" :key="index"></InfoCount>
    </div>
  </div>
</template>

<script>
  import InfoCount from './InfoCount';

  export default {
    name: 'InfoCountList',
    components: { InfoCount },
    props: {
      infoCountList: {
        type: Array,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './src/assets/theme/default/colors';

  .info-count-list {
    display: flex;
    margin-bottom: 24px;

    @media (max-width: $desktop) {
      display: grid;
      gap: 8px 40px;
      grid-template-columns: repeat(2, min-content);
      grid-auto-rows: 2fr;
    }

    @media (max-width: $laptop) {
      grid-template-columns: repeat(1, 100%);
      gap: 4px;
    }

    &__item {
      margin-right: 40px;
      text-wrap: nowrap;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: $desktop) {
        margin-right: 0;
      }
    }
  }
</style>
