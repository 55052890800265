<template>
  <div class="chart-mobile">
    <div class="chart-mobile__row" v-for="(item, index) in options.xaxis.categories" :key="index">
      <span class="chart-mobile__label">{{ formatter(item) }}</span>
      <div class="chart-mobile__wrap-value">
        <span
          class="chart-mobile__value"
          v-for="(seriesItem, seriesIndex) in series"
          :key="seriesIndex"
          :style="[`color: ${options.colors[seriesIndex]}`]"
        >
          {{ seriesItem.data[index] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartMobile',
    props: ['options', 'series'],
    methods: {
      formatter: function (val) {
        if (typeof val === 'object') {
          return `${val[0]} ${val[1]}`;
        }

        return val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './src/assets/theme/default/colors';

  .chart-mobile {
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4px;
      color: $base;
      font: $fira-16;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__label {
      margin-right: 16px;
      width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__wrap-value {
      display: flex;
      flex-direction: row;
    }

    &__value {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8px;
      font: $fira-14-B;

      &:first-child {
        margin-left: 0;
      }
    }
  }
</style>
