<template>
  <div class="cart-indicators">
    <div class="cart-indicators__item" v-for="(item, index) in indicatorsList" :key="index">
      <div class="cart-indicators__icon" :style="[`background-color: rgba(${item.color})`]"></div>
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartIndicators',
    props: ['indicatorsList'],
  };
</script>

<style lang="scss" scoped>
  @import './src/assets/theme/default/colors';

  .cart-indicators {
    display: flex;
    flex-direction: row;

    @media (max-width: $laptop) {
      flex-direction: column;
    }

    &__icon {
      display: inline-block;
      width: 12px;
      min-width: 12px;
      height: 12px;
      border-radius: 50%;
      margin: auto 12px auto 0;
      background-color: $black;
    }

    &__item {
      display: flex;
      flex-direction: row;
      align-content: center;
      margin-right: 32px;
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      color: $black;

      @media (max-width: $laptop) {
        margin-bottom: 8px;
      }

      &:last-child {
        margin-right: 0;

        @media (max-width: $laptop) {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
