// преобразуем query параметр со значением `0` в `null`, чтобы он не отображался в url
const parseParamId = (param) => {
  const parsedParam = parseInt(param);
  if (parsedParam === 0 || Number.isNaN(parsedParam)) {
    return null;
  }
  return parsedParam;
};

export default parseParamId;
