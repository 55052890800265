<template>
  <div :class="[`info-count ${justifyClass}`]">
    <span class="info-count__title">{{ title }}</span>
    <span class="info-count__value">{{ count }}</span>
  </div>
</template>

<script>
  export default {
    name: 'InfoCount',
    props: {
      title: {
        type: String,
        required: true,
      },
      count: {
        type: [Number, String],
        default: '',
        required: true,
      },
      justifyClass: {
        type: String,
        validator: (v) => ['flex-start'].includes(v),
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './src/assets/theme/default/colors';

  .info-count {
    display: flex;
    justify-content: space-between;
    font: $fira-20-M;

    @media (max-width: $laptop) {
      justify-content: space-between;
      font: $fira-16-M;
    }

    &__title,
    &__value {
      font: inherit;
    }

    &__value {
      margin-left: 12px;
      background: $colorGradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .flex-start {
    justify-content: flex-start;

    @media (max-width: $mobile) {
      justify-content: space-between;
    }
  }
</style>
